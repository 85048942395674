'use client';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Center, Flex, Grid, VStack } from '@pt-group-fe/styled-system/jsx';
import { cmsContentDownloadForm } from '@pt-group-fe/styled-system/recipes';
import { saveAs } from 'file-saver';
import { useSearchParams } from 'next/navigation';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useIsMobile, useLanguage, usePageProps } from '@lib/hooks';
import { contact } from '@lib/services/contact';
import { useTranslation } from '@lib/services/i18n/client';
import { Tenant_Uid } from '@lib/services/tenant';
import { Button } from '@lib/ui/button';
import { Checkbox } from '@lib/ui/checkbox';
import { CmsEditorText } from '@lib/ui/cmsEditorText';
import { Form, FormControl, FormField, FormInputText, FormItem, FormMessage } from '@lib/ui/form';
import { Icon } from '@lib/ui/icon';
import { CountrySelect } from '@lib/ui/select';
import { Text } from '@lib/ui/text';
import { getTitleTag } from '@lib/utils';

import { useEnv } from '../envProvider';

import { DownloadFormProps } from './DownloadForm';

type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

const DownloadFormInner: React.FC<
  DownloadFormProps & { isTermsOpen: boolean; setTermsOpen: (open: boolean) => void }
> = ({ data, formId, hasCaptcha, smtpConfig, userEmail, userCompany, userName, setTermsOpen }) => {
  const { captchaSiteKey } = useEnv();
  const {
    teaser,
    title,
    formWrapper,
    resIcon,
    resMsg,
    buttons,
    terms,
    termsCheckboxText,
    termsCheckboxError
  } = cmsContentDownloadForm();
  const {
    params: { tenant }
  } = usePageProps();
  const { t } = useTranslation();
  const pageProps = usePageProps();
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState<string | null>();
  const catpchaRef = useRef<ReCAPTCHA>(null);
  const isMobile = useIsMobile();
  const searchParams = useSearchParams();
  const isTermsEnabled = data.terms && data.terms.checkboxLabel && data.terms.content;
  const contactRef = useMemo(() => searchParams.get('contactRef'), [searchParams]);
  const locale = useLanguage();

  const formSchema = useMemo(() => {
    const schema: any = {};

    if (data.formFields?.includes('message')) {
      schema.message = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.message')
          })
        })
        .min(1)
        .max(2000);
    }

    if (data.formFields?.includes('email')) {
      schema.email = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.email')
          })
        })
        .email({
          message: t('uiWeb.cmsContents.contact.formItem.emailInvalid')
        });
    }
    if (data.formFields?.includes('name')) {
      schema.name = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.name')
          })
        })
        .max(100);
    }

    if (data.formFields?.includes('topic')) {
      schema.topic = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.topic')
          })
        })
        .max(100);
    }
    if (data.formFields?.includes('country')) {
      schema.country = z.string().max(2).optional();
    }

    if (data.formFields?.includes('company')) {
      schema.company = z
        .string({
          required_error: t('uiWeb.cmsContents.contact.field.fieldRquired', {
            name: t('uiWeb.cmsContents.contact.field.company')
          })
        })
        .max(100);
    }
    if (data.formFields?.includes('industry')) {
      schema.industry = z.string().max(100).optional();
    }
    if (data.formFields?.includes('phone')) {
      schema.phone = z.string().max(100).optional();
    }

    if (isTermsEnabled) {
      schema.terms = z.literal(true, {
        errorMap: () => ({
          message: t('uiWeb.cmsContents.contact.field.termsMustBeAccepted')
        })
      });
    }

    return z.object(schema);
  }, [data.formFields, isTermsEnabled, t]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema as any),
    defaultValues: {
      email: userEmail,
      company: userCompany,
      name: userName
    }
  });

  const isBtnDisalbed = useMemo(() => {
    if (status === 'loading') return true;

    if (hasCaptcha === false) return false;

    return googleRecaptchaToken === undefined;
  }, [googleRecaptchaToken, hasCaptcha, status]);

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (!googleRecaptchaToken && hasCaptcha !== false) return;

      setStatus('loading');

      if (data.downloadFile.data) {
        saveAs(data.downloadFile.data.url, data.downloadFile.data.name);
      }

      const isSuccess = await contact.sendContactRequest({
        pageProps,
        formData: values as any,
        url: window.location.href,
        tenantUid: tenant as Tenant_Uid,
        googleRecaptchaToken,
        context: data.context,
        referer: contactRef,
        emailSubject: data.emailSubject,
        smtpConfigOverwrite: smtpConfig
      });

      if (!isSuccess) {
        setStatus('error');

        return;
      }

      setStatus('success');
    },
    [
      contactRef,
      data.context,
      data.downloadFile.data,
      data.emailSubject,
      googleRecaptchaToken,
      hasCaptcha,
      pageProps,
      smtpConfig,
      tenant
    ]
  );

  const handleCaptcha = (token: string | null) => {
    setGoogleRecaptchaToken(token);
  };

  const handleReset = () => {
    setStatus('idle');
    form.reset();
  };

  useEffect(() => {
    setStatus('idle');
  }, []);

  useEffect(() => {
    if (!isMobile || status !== 'success') return;

    const el = document.getElementById(formId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  }, [formId, isMobile, status]);

  return (
    <>
      {status !== 'success' && status !== 'error' && (
        <>
          <Text className={teaser} variant="teaser">
            {data.teaser}
          </Text>
          <Text className={title} variant="h2" as={getTitleTag(data.titleHtmlTag, 'h2')}>
            {data.title}
          </Text>
          <CmsEditorText variant="blockLg" data={data.text} />

          <div className={formWrapper}>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Flex gap={4} flexDir="column">
                  <Grid gap={4} columns={{ base: 1, md: 2 }}>
                    {data.formFields.includes('email') && (
                      <FormField
                        name="email"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            isMui
                            label={t('uiWeb.cmsContents.contact.field.email')}
                            required
                            field={field as any}
                            {...field}
                          />
                        )}
                      />
                    )}
                    {data.formFields.includes('name') && (
                      <FormField
                        name="name"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            required
                            label={t('uiWeb.cmsContents.contact.field.name')}
                            {...field}
                          />
                        )}
                      />
                    )}

                    {data.formFields.includes('phone') && (
                      <FormField
                        name="phone"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            label={t('uiWeb.cmsContents.contact.field.phone')}
                          />
                        )}
                      />
                    )}

                    {data.formFields.includes('topic') && (
                      <FormField
                        name="topic"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            required
                            label={t('uiWeb.cmsContents.contact.field.topic')}
                          />
                        )}
                      />
                    )}

                    {data.formFields.includes('company') && (
                      <FormField
                        name="company"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            required
                            label={t('uiWeb.cmsContents.contact.field.company')}
                          />
                        )}
                      />
                    )}

                    {data.formFields.includes('industry') && (
                      <FormField
                        name="industry"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            label={t('uiWeb.cmsContents.contact.field.industry')}
                          />
                        )}
                      />
                    )}

                    {data.formFields.includes('country') && (
                      <FormField
                        name="country"
                        control={form.control}
                        render={({ field }) => (
                          <CountrySelect
                            label={t('uiWeb.cmsContents.contact.field.country')}
                            {...field}
                          />
                        )}
                      />
                    )}
                  </Grid>

                  {data.formFields.includes('message') && (
                    <FormField
                      name="message"
                      control={form.control}
                      render={({ field }) => (
                        <FormInputText
                          label={t('uiWeb.cmsContents.contact.field.message')}
                          required
                          multiLine
                          isMui
                          rows={4}
                          field={field as any}
                          {...field}
                        />
                      )}
                    />
                  )}

                  {isTermsEnabled && (
                    <FormItem className={terms}>
                      <FormField
                        control={form.control}
                        name="terms"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormControl>
                                <div>
                                  <Flex gap={1} alignItems="flex-start">
                                    <Checkbox
                                      checked={field.value}
                                      onCheckedChange={checked => {
                                        field.onChange(checked);
                                      }}
                                    />
                                    <button
                                      onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setTermsOpen(true);
                                      }}
                                    >
                                      <CmsEditorText
                                        variant="blockSm"
                                        data={data.terms?.checkboxLabel}
                                        className={termsCheckboxText}
                                      />
                                    </button>
                                  </Flex>
                                  <FormMessage className={termsCheckboxError} />
                                </div>
                              </FormControl>
                            </FormItem>
                          );
                        }}
                      />
                    </FormItem>
                  )}

                  {captchaSiteKey && hasCaptcha !== false && (
                    <ReCAPTCHA
                      sitekey={captchaSiteKey}
                      onChange={handleCaptcha}
                      ref={catpchaRef}
                      lang={locale}
                    />
                  )}

                  <div className={buttons}>
                    <Button
                      size="default"
                      variant="primary"
                      icon="arrow-right"
                      type="submit"
                      loading={status === 'loading'}
                      disabled={isBtnDisalbed}
                    >
                      {t('uiWeb.cmsContents.contact.btn.labelDownload')}
                    </Button>
                  </div>
                </Flex>
              </form>
            </Form>
          </div>
        </>
      )}

      {status === 'success' && (
        <Center h="full">
          <VStack>
            <Icon i="check-circle" className={resIcon} />
            <div className={title}>
              <Text variant="block" className={resMsg}>
                {t('uiWeb.cmsContents.contact.success')}
              </Text>
              <Text variant="block" className={resMsg}>
                {t('uiWeb.cmsContents.contact.successTwo')}
              </Text>
            </div>
            <Button size="default" variant="secondary" onClick={handleReset}>
              {t('uiWeb.cmsContents.contact.succBtn.label')}
            </Button>
          </VStack>
        </Center>
      )}

      {status === 'error' && (
        <Center h="full">
          <VStack>
            <Icon i="flag-exclamation" className={resIcon} />
            <div className={title}>
              <Text variant="block" className={resMsg}>
                {t('uiWeb.cmsContents.contact.error')}
              </Text>
              <Text variant="block" className={resMsg}>
                {t('uiWeb.cmsContents.contact.errorTwo')}
              </Text>
            </div>
            <Button size="default" variant="secondary" onClick={handleReset}>
              {t('uiWeb.cmsContents.contact.succBtn.label')}
            </Button>
          </VStack>
        </Center>
      )}
    </>
  );
};

export default DownloadFormInner;
